import request from '@/utils/request'

// 分页查询售后信息
export const expeditedAfterSaleList = (params) => {
  return request({
    url: '/order/after/sale/store/expeditedAfterSaleList',
    method: 'get',
    params,
  })
}
// 详情查询
export const queryAfterSaleDetail = (id) => {
  return request({
    url: '/order/after/sale/queryAfterSaleDetail/' + id,
    method: 'get',
  })
}
// 售后更新(提交售后完结)
export const update = (data) => {
  return request({
    url: '/order/after/sale/update',
    method: 'put',
    data:data
  })
}
// 获取工厂下拉框
export const saveFactory = () => {
  return request({
    url: 'sys/store/list/factory',
    method: 'get',
  })
}
// 门店查询售后详情，需变更查看状态
export const queryAfterSaleDetailState = (id) => {
  return request({
    url: '/order/after/sale/store/queryAfterSaleDetail/' + id,
    method: 'get',
  })
}
//统计售后各个状态未查看数
export const statistics = () => {
  return request({
    url:'/order/after/sale/status/statistics',
    method:'get'
  })
}
//获取售后统计数据
export const getAfterSaleCount = (params) => {
  return request({
    method: 'get',
    url: `/order/after/sale/getAfterSaleCount`,
    params,
  })
}
