<template>
  <el-dialog
    center
    :show-close="false"
    :visible="dialogTableVisible"
    top="5vh"
    width="1000px"
  >
    <div slot="title" class="header-title">
      <i
        class="el-icon-close"
        style="font-size: 20px; float: right"
        @click="closeAfterSale"
      ></i>
    </div>
    <el-row class="afterDetails-top">
      <el-row>
        <span class="afterDetails-top-left">
          <span
            v-if="details.afterSaleStatusDesc == '待处理'"
            class="el-icon-video-play"
            style="color: red"
          ></span>
          <span
            v-if="details.afterSaleStatusDesc == '处理中'"
            class="el-icon-time"
            style="color: blue"
          ></span>
          <span
            v-if="details.afterSaleStatusDesc == '已完成'"
            class="el-icon-circle-check"
            style="color: green"
          ></span>
          <span
            v-if="details.afterSaleStatusDesc == '已完结'"
            class="el-icon-circle-check"
            style="color: skyblue"
          ></span>

          <span>{{ details.afterSaleStatusDesc }}</span>
          <span v-if="details.afterSaleStatusDesc == '待处理'">
            工厂已提交售后申请,待售后处理
          </span>
        </span>
        <span class="afterDetails-top-right">
          <span>申请工厂：{{ details.factoryName }}</span>
          <span>申请日期：{{ details.createdTime }}</span>
        </span>
      </el-row>
    </el-row>

    <el-steps :active="active" align-center>
      <el-step v-for="(item, index) in operationVOList" :key="index">
        <template slot="title">
          <div
            :style="
              item.active == true
                ? 'fontSize: 12px;color: #000'
                : 'fontSize: 12px;color: #c0c4cc'
            "
          >
            {{ item.operationDesc }}
          </div>
        </template>
        <template slot="icon">
          <i :class="item.active ? 'icon_class' : ''"></i>
        </template>
        <template slot="description">
          <div style="font-size: 14px; color: #000">
            {{ item.operateUserName }}
          </div>
          <div style="font-size: 12px; color: rgba(150, 153, 159, 100)">
            {{ item.operationTime }}
          </div>
        </template>
      </el-step>
    </el-steps>
    <el-row style="padding: 25px 0 0 20px">
      <el-row>
        <span class="icon-box"></span>
        <span class="title-box">衣物信息</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="content-title" style="margin-left：14px">
            <span style="padding-left: 14px">衣物号：</span>
            <span style="padding-left: 5px">{{ details.outClothesNum }}</span>
          </div>
          <div class="content-title">
            衣物品牌：
            <span style="padding-left: 5px">{{
              details.brandName ? details.brandName : "无"
            }}</span>
          </div>
          <div class="content-title">
            衣物状态：
            <span style="padding-left: 5px">{{
              details.clothesStatusDesc
            }}</span>
          </div>
          <div class="content-title voucher" style="display:flex;">
            <span>工厂图片：</span>
            <div
              class="imgbox"
              v-if="details.factoryPhotos && details.factoryPhotos.length != 0"
              style="width:23vw"
            >
              <el-image
                v-for="(item, index) in details.factoryPhotos"
                v-show="index < 5"
                :key="index"
                class="img"
                :preview-src-list="details.factoryPhotos"
                :src="item"
              />
              <span
                v-show="
                  details.factoryPhotos
                    ? details.factoryPhotos.length > 5
                    : false
                "
                class="imgs1"
              >
                <el-image
                  v-if="details.factoryPhotos.length >= 5"
                  class="img"
                  :preview-src-list="details.factoryPhotos"
                  :src="details.factoryPhotos[5]"
                  style="position: absolute; left: 0; opacity: 0"
                />
                +{{ details.factoryPhotos.length - 5 }}
              </span>
            </div>
            <span v-else>暂无图片</span>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            衣物类型：
            <span style="padding-left: 5px">{{ details.categoryName }}</span>
          </div>
          <div class="content-title">
            衣物颜色：
            <span style="padding-left: 5px">{{
              details.colorName ? details.colorName : "无"
            }}</span>
          </div>
          <div class="content-title">
            收衣时间：
            <span style="padding-left: 5px">{{
              details.collectTime ? details.collectTime : "无"
            }}</span>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            衣物名称：
            <span style="padding-left: 5px">{{ details.clothesName }}</span>
          </div>
          <div class="content-title" style="width: 380px">
            衣物瑕疵：
            <el-tooltip
              v-if="details.blemish"
              class="item"
              effect="dark"
              :content="details.blemish"
              placement="top"
              style="width: 200px"
            >
              <span class="clothing-defect flaw-box">{{
                details.blemish
              }}</span>
            </el-tooltip>
            <span v-else>无</span>
          </div>
          <div class="content-title voucher" style="display:flex;">
            <span>门店图片：</span>
            <div class="imgbox" v-if="details.clothesImage" style="width:23vw">
              <el-image
                v-for="(item, index) in details.clothesImage"
                v-show="index < 5"
                :key="index"
                class="img"
                :preview-src-list="details.clothesImage"
                :src="item"
              />
              <span
                v-show="
                  details.clothesImage ? details.clothesImage.length > 5 : false
                "
                class="imgs1"
              >
                <el-image
                  v-if="details.clothesImage.length >= 5"
                  class="img"
                  :preview-src-list="details.clothesImage"
                  :src="details.clothesImage[5]"
                  style="position: absolute; left: 0; opacity: 0"
                />
                +{{ details.clothesImage.length - 5 }}
              </span>
            </div>
            <span v-else>暂无图片</span>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row style="padding: 15px 0 0 20px">
      <el-row>
        <span class="icon-box"></span>
        <span class="title-box">售后信息</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="content-title">
            售后单号：
            <span style="padding-left: 5px">{{ details.afterSaleId }}</span>
          </div>
          <div
            v-if="details.logisticsMethodDesc != '当面交接'"
            class="content-title"
          >
            <span>
              {{
                details.logisticsMethodDesc == "物流"
                  ? "物流袋："
                  : "快递单号："
              }}
            </span>
            <el-tooltip
              v-if="
                details.logisticsMethodDesc &&
                  details.logisticsMethodDesc == '物流'
              "
              class="item"
              :content="details.logisticsBag"
              effect="dark"
              placement="top-start"
            >
              <span class="beyond-hidden flaw-box" style="width:120px">
                {{ details.logisticsBag }}
              </span>
            </el-tooltip>
            <el-tooltip
              v-if="
                details.logisticsMethodDesc &&
                  details.logisticsMethodDesc == '快递'
              "
              class="item"
              :content="details.trackingNumber"
              effect="dark"
              placement="top-start"
            >
              <span class="beyond-hidden flaw-box" style="width:120px">
                {{ details.trackingNumber }}
              </span>
            </el-tooltip>
            <span style="padding-left: 5px" v-if="!details.logisticsMethodDesc">
              无
            </span>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            是否实物：
            <span style="padding-left: 5px">{{
              details.materialObjectDesc
            }}</span>
          </div>
          <div class="content-title" style="width: 380px">
            售后描述：
            <el-tooltip
              class="item"
              effect="dark"
              :content="details.problemDescription"
              placement="top"
              style="width: 180px;"
            >
              <span
                class="clothing-defect flaw-box"
                style="margin-top: -4px;"
                >{{ details.problemDescription }}</span
              >
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            物流方式：
            <span style="padding-left: 5px">{{
              details.logisticsMethodDesc ? details.logisticsMethodDesc : "无"
            }}</span>
          </div>
          <div class="content-title voucher" style="display: flex;">
            <span>上传图片：</span>
            <div
              class="imgbox"
              style="width:21vw"
              v-if="details.newCredentialList"
            >
              <el-image
                v-for="(item, index) in details.newCredentialList"
                v-show="index < 5"
                :key="index"
                class="img"
                :preview-src-list="details.newCredentialList"
                :src="item"
              />
              <span
                v-show="
                  details.newCredentialList
                    ? details.newCredentialList.length > 5
                    : false
                "
                class="imgs1"
              >
                <el-image
                  v-if="details.newCredentialList.length >= 5"
                  class="img"
                  :preview-src-list="details.newCredentialList"
                  :src="details.newCredentialList[5]"
                  style="position: absolute; left: 0; opacity: 0"
                />
                +{{ details.newCredentialList.length - 5 }}
              </span>
            </div>
            <span v-else>暂无图片</span>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row style="padding: 15px 0 5px 20px">
      <el-row>
        <span class="icon-box"></span>
        <span class="title-box">订单信息</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="content-title">
            <span class="text-style">订单号：</span>
            <span>{{ details.orderNumber }}</span>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            <span class="text-style">用户名称：</span>
            <span>{{ details.userName }}</span>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            <span class="text-style">用户手机号：</span>
            <span v-hidephone>{{ details.userPhone }}</span>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row style="padding: 15px 0 5px 20px">
      <el-row>
        <span class="icon-box"></span>
        <span class="title-box">瑕疵信息</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="content-title">
            <span class="text-style"> 是否瑕疵确认：</span>
            <span>{{ details.flawFlag ? details.flawFlag : "无" }}</span>
          </div>
          <div class="content-title" v-if="details.flawFlag == '是'">
            <span class="text-style"> 瑕疵性质：</span>
            <span>{{
              details.flawedNatureDesc ? details.flawedNatureDesc : "无"
            }}</span>
          </div>
          <div
            class="content-title"
            style="width:380px"
            v-if="details.flawFlag == '是'"
          >
            门店回复：
            <el-tooltip
              class="item"
              v-if="details.storeExplain"
              effect="dark"
              :content="details.storeExplain"
              placement="top"
              style="width: 180px"
            >
              <span class="clothing-defect flaw-box">{{
                details.storeExplain
              }}</span>
            </el-tooltip>
            <span v-else>
              无
            </span>
          </div>
        </el-col>
        <el-col :span="7" v-if="details.flawFlag == '是'">
          <div class="content-title">
            <span class="text-style">发布瑕疵人：</span>
            <span>{{
              details.factoryUserName ? details.factoryUserName : "无"
            }}</span>
          </div>
          <div class="content-title" style="width:380px">
            瑕疵描述：
            <el-tooltip
              class="item"
              v-if="details.factoryExplain"
              effect="dark"
              :content="details.factoryExplain"
              placement="top"
              style="width: 180px"
            >
              <span class="clothing-defect flaw-box">{{
                details.factoryExplain
              }}</span>
            </el-tooltip>
            <span v-else>
              无
            </span>
          </div>
          <div class="content-title">
            <span class="text-style">回复时间：</span>
            <span>{{
              details.repairTypeTime ? details.repairTypeTime : "无"
            }}</span>
          </div>
        </el-col>
        <el-col :span="7" v-if="details.flawFlag == '是'">
          <div class="content-title">
            <span class="text-style"> 瑕疵类型：</span>
            <span>{{
              details.flawedTypeDesc ? details.flawedTypeDesc : "无"
            }}</span>
          </div>
          <div class="content-title">
            <span class="text-style">是否同意：</span>
            <span>{{
              details.repairTypeDesc ? details.repairTypeDesc : "无"
            }}</span>
          </div>
          <div class="content-title voucher" style="display: flex;">
            <span>瑕疵图片：</span>
            <div
              class="imgbox"
              style="width:21vw"
              v-if="factoryPhotos"
            >
              <el-image
                v-for="(item, index) in factoryPhotos"
                v-show="index < 5"
                :key="index"
                class="img"
                :preview-src-list="factoryPhotos"
                :src="item"
              />
              <span
                v-show="
                  factoryPhotos
                    ? factoryPhotos.length > 5
                    : false
                "
                class="imgs1"
              >
                <el-image
                  v-if="factoryPhotos.length >= 5"
                  class="img"
                  :preview-src-list="factoryPhotos"
                  :src="factoryPhotos[5]"
                  style="position: absolute; left: 0; opacity: 0"
                />
                +{{ factoryPhotos.length - 5 }}
              </span>
            </div>
            <span v-else>暂无图片</span>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row
      style="padding: 15px 0 5px 20px"
      v-if="details.afterSaleStatusDesc != '待处理'"
    >
      <el-row>
        <span class="icon-box"></span>
        <span class="title-box">受理信息</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="content-title">
            <span>责任判定：</span>
            <span style="padding-left: 5px">{{
              details.responsibilityJudgmentDesc
            }}</span>
          </div>
          <div class="content-title" style="width:380px">
            具体方案：
            <el-tooltip
              class="item"
              effect="dark"
              :content="details.specificPlan"
              placement="top"
              style="width: 180px"
            >
              <span class="clothing-defect flaw-box">{{
                details.specificPlan
              }}</span>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="content-title">
            <span>处理方式：</span>
            <span style="padding-left: 5px">{{ details.handleSolution }}</span>
          </div>
          <div class="content-title">
            <span style="text-align: left">预计处理时间：</span>
            <span style="padding-left: 5px">{{
              details.estimatedHandleTime
            }}</span>
          </div>
        </el-col>
         <el-col :span="7">
          <div class="content-title">
            <span>售后关联账号：</span>
            <span style="padding-left: 5px">{{ details.countPersonName }}</span>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row
      style="padding: 15px 0 5px 20px"
      v-if="details.treatmentResultsDesc != null"
    >
      <el-row>
        <span class="icon-box"></span>
        <span class="title-box">救治结果</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="content-title" style="margin-left：14px">
            <span>救治结果：</span>
            <span style="padding-left: 5px">{{
              details.treatmentResultsDesc
            }}</span>
          </div>
          <div
            class="content-title"
            v-if="details.treatmentResultsDesc != '成功'"
          >
            是否转理赔：
            <span style="padding-left: 5px">{{
              details.transferClaimDesc
            }}</span>
          </div>
        </el-col>

        <el-col :span="7" v-if="details.treatmentResultsDesc == '成功'">
          <div class="content-title" style="width: 380px">
            成功描述：
            <el-tooltip
              class="item"
              effect="dark"
              :content="details.failReason"
              placement="top"
              style="width: 200px"
            >
              <span class="clothing-defect flaw-box">{{
                details.failReason
              }}</span>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="7" v-if="details.treatmentResultsDesc != '成功'">
          <div class="content-title" style="width: 380px">
            失败原因：
            <el-tooltip
              class="item"
              effect="dark"
              :content="details.failReason"
              placement="top"
              style="width: 200px"
            >
              <span class="clothing-defect flaw-box">{{
                details.failReason
              }}</span>
            </el-tooltip>
          </div>
          <div class="content-title" style="width:380px;padding-top: 5px">
            理赔方案：
            <el-tooltip
              class="item"
              effect="dark"
              :content="details.secondClaimsSolution"
              placement="top"
              style="width: 200px"
            >
              <span class="clothing-defect flaw-box">{{
                details.secondClaimsSolution
              }}</span>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="7" v-if="details.treatmentResultsDesc != '成功'">
          <div class="content-title">
            <span style="padding-left: 5px"></span>
          </div>
          <div class="content-title" style="width: 380px;padding-top: 12px;">
            预计完结时间：
            <span style="padding-left: 5px">{{
              details.secondEstimatedHandleTime
            }}</span>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row class="afterDetails-bottom" style="padding-left: 20px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="跟踪记录" name="first">
          <div class="record-box">
            <el-row
              v-for="(i, index) in details.afterSaleTrackList"
              :key="index"
              style="padding-bottom: 5px"
            >
              <div style="color: rgba(150, 153, 159, 100)">
                {{ i.createdTime }}
              </div>
              <div style="width: 200px">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="i.trackDescribe"
                  placement="top"
                >
                  <span class="clothing-defect flaw-box">
                    {{ i.trackDescribe }}
                  </span>
                </el-tooltip>
              </div>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="second">
          <div class="record-box">
            <el-row
              style="padding-bottom: 5px"
              v-for="(i, index) in details.afterSaleOperationList"
              :key="index"
            >
              <div style="color: rgba(150, 153, 159, 100)">
                {{ i.operationTime }}
              </div>
              <div style="width: 200px">{{ i.operationDesc }}</div>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="完结信息"
          v-if="
            details.afterSaleStatusDesc == '已完成' ||
              details.afterSaleStatusDesc == '已完结' ||
              details.afterSaleLink == 5
          "
          name="c"
        >
          <div class="record-box">
            <el-row :gutter="20" style="margin: 0">
              <el-col :span="8">
                <div class="content-title" style="width: 380px">
                  完结信息：
                  <el-tooltip
                    v-if="details.endInformation"
                    class="item"
                    effect="dark"
                    :content="details.endInformation"
                    placement="top-start"
                    style="width: 200px"
                  >
                    <span class="beyond-hidden flaw-box">{{
                      details.endInformation
                    }}</span>
                  </el-tooltip>
                  <span v-else style="padding-left: 5px">{{
                    details.endInformation
                  }}</span>
                </div>
                <div class="content-title">
                  物流方式：
                  <span style="padding-left: 5px">{{
                    details.endLogisticsMethodDesc
                      ? details.endLogisticsMethodDesc
                      : "无"
                  }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="content-title voucher" style="display: flex;">
                  <span>上传图片：</span>
                  <div
                    class="imgbox"
                    style="width: 21vw"
                    v-if="details.endCredentialList"
                  >
                    <el-image
                      v-for="(item, index) in details.endCredentialList"
                      v-show="index < 5"
                      :key="index"
                      class="img"
                      :preview-src-list="details.endCredentialList"
                      :src="item"
                    />
                    <span
                      v-show="
                        details.endCredentialList
                          ? details.endCredentialList.length > 5
                          : false
                      "
                      class="imgs1"
                    >
                      <el-image
                        v-if="details.endCredentialList.length >= 5"
                        class="img"
                        :preview-src-list="details.endCredentialList"
                        :src="details.endCredentialList[5]"
                        style="position: absolute; left: 0; opacity: 0"
                      />
                      +{{ details.endCredentialList.length - 5 }}
                    </span>
                    <!-- <el-image
                      class="img"
                      v-for="src in details.endCredentialList"
                      :key="src"
                      :src="src"
                      :preview-src-list="details.endCredentialList"
                    >
                    </el-image> -->
                  </div>
                  <span v-else>暂无图片</span>
                </div>
                <div class="content-title">
                  {{
                    details.endLogisticsMethod == 1
                      ? "&nbsp;&nbsp;&nbsp;&nbsp;物流袋："
                      : "快递单号："
                  }}
                  <el-tooltip
                    v-if="details.endLogisticsMethod == 1"
                    class="item"
                    effect="dark"
                    :content="details.endLogisticsBag"
                    placement="top-start"
                    style="width: 200px"
                  >
                    <span class="beyond-hidden flaw-box">{{
                      details.endLogisticsBag
                    }}</span>
                  </el-tooltip>
                  <span
                    style="padding-left: 5px"
                    v-if="details.endLogisticsMethod == 2"
                    >{{ details.endTrackingNumber }}</span
                  >
                  <span
                    style="padding-left: 5px"
                    v-if="details.endLogisticsMethod == 3"
                    >无</span
                  >
                  <span
                    style="padding-left: 5px"
                    v-if="details.endLogisticsMethod == null"
                    >无</span
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: false,
    },
    detailsData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      activeName: "first",
      details: [], //详情数据
      operationVOList: [],
      imgViewerVisible: false,
      active: null,
      factoryPhotos: [],
    };
  },
  watch: {
    detailsData(val) {
      this.details = {
        ...val.factoryFlawVO,
        ...val,
      };
      this.factoryPhotos = val.factoryFlawVO.factoryPhotos;
      this.operationVOList = val.operationVOList;
      let len = this.operationVOList.filter((item) => {
        return item.active === true;
      });
      this.active = len.length;
    },
  },
  methods: {
    closeAfterSale() {
      this.$emit("closeAfterSale");
    },
    endAfter() {
      this.$emit("endAfter", this.details);
    },
    handleClick() {},
  },
};
</script>

<style scoped lang="scss">
//   ::v-deep .el-dialog__body {
//     padding: 10px 40px 20px;
//   }
.afterDetails-top {
  background-color: rgba(244, 244, 244, 100) !important;
  padding: 25px;
  margin-bottom: 20px;
  .afterDetails-top-left {
    float: left;
    span:nth-child(1) {
      color: rgba(53, 59, 254, 100);
      font-size: 24px;
      position: relative;
      top: 3px;
    }
    span:nth-child(2) {
      color: rgba(16, 16, 16, 100);
      font-size: 16px;
      padding: 0 15px 0 5px;
      font-weight: 700;
    }
    span:nth-child(3) {
      color: rgba(180, 180, 181, 100);
      font-size: 14px;
    }
  }
  .afterDetails-top-right {
    padding-top: 5px;
    float: right;
    span: {
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
    }
    span:nth-child(1) {
      padding-right: 20px;
    }
  }
  .steps {
    display: flex;
    flex-direction: row;
  }
}
::v-deep .el-step__icon.is-text {
  height: 12px;
  width: 12px;
  background: #1a5eff;
  background: #c0c4cc;
  border: #fff 2px solid;
  position: relative;
  top: 3px;
  i {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .icon_class {
    background: #1a5eff;
  }
}
.icon-box {
  position: absolute;
  width: 3px;
  height: 18px;
  background: #3894ff;
}
.title-box {
  padding-left: 10px;
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: 700;
}
.content-title {
  color: rgba(54, 54, 54, 100);
  font-size: 14px;
  padding: 10px 0;
  .text-style {
    padding-top: 10px;
  }
}
.afterDetails-bottom {
  padding-left: 20px;
}
.afterDetails-button {
  padding-top: 20px;
  text-align: center;
}
.record-box {
  height: 200px;
  overflow: auto;
}
.clothing-defect {
  padding-left: 5px;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.flaw-box {
  // padding: 0 0 5px;
  min-width: 100px;
  display: inline-block;
  vertical-align: bottom;
}

.beyond-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher {
  display: flex;
  flex-wrap: wrap;
  width: 35vw;
  .imgbox {
    display: flex;
    flex-wrap: wrap;
    width: 15vw;
  }
  .img {
    width: 40px;
    margin: 0 10px 10px 0;
    height: 40px;
  }
}
.imgs1 {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e8e8e8;
  cursor: pointer;
  position: relative;
}
.imgs {
  display: flex;
  flex-wrap: wrap;
  width: 35vw;
  .imgbox {
    display: flex;
    flex-wrap: wrap;
    width: 30vw;
  }
  .img {
    width: 40px;
    margin: 0 10px 10px 0;
    height: 40px;
  }
}
</style>
