<template>
  <div v-loading="loading" class="afterSaleManagement content-index">
    <el-row class="afterSale-title">
      <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="售后订单"
      ></el-page-header>
    </el-row>
    <Search
      :search-data="searchData"
      :loading="loading"
      @searchChange="searchChange"
    />
    <el-tabs v-model="pageData.activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        :label="item.label"
        :name="item.name"
        v-for="(item, index) in tabPane"
        :key="index"
      >
        <div slot="label">
              <el-badge
                  :value="item.notViewed"
                  class="item"
                  :max="99"
                  v-if="item.notViewed > 0"
              >
                <span>{{ item.label }}</span>
                <span>（{{ item.quantity }}）</span>
              </el-badge>
              <div v-else>
                <span>{{ item.label }}</span>
                <span>（{{ item.quantity }}）</span>
              </div>
            </div>
      </el-tab-pane>
      <Table
        :border-bool="true"
        :button-type="buttonList"
        :current-value="pageData.current"
        :header-style="headerStyle"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        :height="'62vh'"
        @operationClick="operationClick"
        @handleCurrentChange="handleCurrentChange"
      />
    </el-tabs>
    <AfterDetails
      :dialog-table-visible="dialogAfterDetails"
      @closeAfterSale="closeAfterSale"
      @endAfter="endAfter"
      :details-data="detailsData"
    />
  </div>
</template>

<script>
import Search from "./components/search";
import Table from "./components/table";
import AfterDetails from "./components/afterDetails";
import {
  expeditedAfterSaleList,
  queryAfterSaleDetail,
  saveFactory,
  queryAfterSaleDetailState,
  statistics,
  getAfterSaleCount
} from "@/api/afterSale/afterSale";

export default {
  components: {
    Search,
    Table,
    AfterDetails,
  },
  data() {
    return {
      dialogAfterDetails: false,
      searchData: [
        {
          prop: "clothingBarcode",
          label: "衣物条码",
          type: "text",
        },
        {
          prop: "factoryId",
          label: "工厂名称",
          type: "select",
          selectArr: [],
        },
        {
          prop: "applicationTime",
          label: "申请时间",
          type: "DateTime",
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
          ],
           labelWidth:'20px'
        },
        (this.loading = false),
      ],
      pageData: {
        current: 1,
        size: 10,
        activeName: "1",
        afterSaleStatus: "1",
        factoryId: "",
        outClothesNum: "",
        beginTime: "",
        endTime: "",
        applicationTime: [],
      },
      loading: false,
      tabsState: 0,
      headerStyle: {
        background: "#FFF",
      },
      buttonList: [
        {
          name: "查看详情",
          size: "medium",
          type: "text",
          code: "details",
          width: "180",
        },
      ],
      tableData: [],
      height: "660",
      total: 0,
      titleListData: [
        {
          prop: "afterSaleTypeDesc",
          label: "售后类型",
          width: "150",
        },
        {
          prop: "factoryName",
          label: "工厂",
          width: "150",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: "150",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: "150",
        },
        {
          prop: "createdTime",
          label: "售后时间",
          width: "250",
        },
      ],
      tabPane: [
        {
          label: "全部",
          name: "0",
          quantity: ''
        },
        {
          label: "待处理",
          name: "1",
          notViewed: "",
          quantity: ''
        },
        {
          label: "处理中",
          name: "2",
          notViewed: "",
          quantity: ''
        },
        {
          label: "已完结",
          name: "3",
          notViewed: "",
          quantity: ''
        },
        {
          label: "已完成",
          name: "4",
          notViewed: "",
          quantity: ''
        },
      ],
      detailsData: {}, //详情数据
      count: {}
    };
  },
  created() {
    this.pagingQuery();
    this.saveFactory();
    this.statistics();
    this.getAfterSaleCount()
  },
  methods: {
    // tabs切换
    handleClick(val) {
      if (val.index === "0") {
        delete this.pageData.afterSaleStatus;
      } else {
        this.pageData.afterSaleStatus = val.index;
      }
      this.tabsNum = val.index;
      this.pageData.current = 1;
      this.statistics();
      this.pagingQuery();
    },
    // 表格页面变化
    handleCurrentChange(value) {
      console.log(value, "页码");
      this.pageData.current = value;
      this.pagingQuery();
    },
    // 点击操作里的按钮
    operationClick({ row, code }) {
      if (code == "details") {
        console.log(row, "详情");
        this.dialogAfterDetails = true;
        //详情查询
        queryAfterSaleDetail(row.afterSaleId).then((res) => {
          this.detailsData = res.data.data;
          console.log(this.detailsData, "详情数据");
        });
        //查看状态
        if (row.viewStatus == 0) {
          queryAfterSaleDetailState(row.afterSaleId).then((res) => {
            if (res.data.code == 0) {
              this.pagingQuery();
              this.statistics();
            }
          });
        }
      }
    },
    // 搜索按钮出发
    searchChange({ code, formData }) {
      if (code == "search") {
        console.log(formData, "---");
        this.pageData.outClothesNum = formData.clothingBarcode;
        this.pageData.factoryId = formData.factoryId;
        this.pageData.applicationTime = formData.applicationTime;
        this.pageData.beginTime = formData.applicationTime
          ? formData.applicationTime[0]
          : "";
        this.pageData.endTime = formData.applicationTime
          ? formData.applicationTime[1]
          : "";
        this.pagingQuery();
      }else if(code == 'reset'){
        this.pageData = {
          current: 1,
          size: 10,
          activeName: "1",
          afterSaleStatus: "1",
        },
        this.pagingQuery();
      }
    },
    //获取工厂名称
    async saveFactory() {
      const { data: res } = await saveFactory();
      if (res.code != 0) return this.$message.error("获取工厂名称失败");
      this.searchData[1].selectArr = res.data;
    },
    // 售后完结
    endAfter() {},
    // 关闭详情
    closeAfterSale() {
      this.dialogAfterDetails = false;
    },
    //分页查询
    pagingQuery() {
      this.loading = true;
      expeditedAfterSaleList(this.pageData).then(({ data }) => {
        if (data.code == 0) {
          this.total = data.data.total;
          this.tableData = data.data.records;
          console.log(this.tableData, "数据集合");
        } else {
          this.$message.error(data.msg);
        }
        this.loading = false;
      });
    },
    //统计未查看
    statistics() {
      statistics().then((res) => {
        let data = res.data.data;
        this.tabPane = this.tabPane.map((item, index) => {
          if (index == 1 && data.toBeProcessedCount != 0) {
            item.notViewed = data.toBeProcessedCount;
          } else if (index == 2 && data.processingCount != 0) {
            item.notViewed = data.processingCount;
          } else if (index == 3 && data.finishedCount != 0) {
            item.notViewed = data.finishedCount;
          } else if (index == 4 && data.completedCount != 0) {
            item.notViewed = data.completedCount;
          } else if (index == 5 && data.terminatedC != 0) {
            item.notViewed = data.terminatedC;
          } else {
            item.notViewed = null;
          }
          return item;
        });
        console.log("未查看");
      });
    },
    //获取售后统计数据
    getAfterSaleCount() {
      let params = {
        storeId: this.$store.state.user.userInfo.storeId
      }
      console.log(this.$store.state.user.userInfo.storeId,'99')
      getAfterSaleCount(params).then((res) => {
        let data = res.data.data;
        this.tabPane = this.tabPane.map((item, index) => {
          if (index == 0 && data.afterSaleAllCount != 0) {
            item.quantity = data.afterSaleAllCount;
          } else if (index == 1 && data.pendingCount != 0) {
            item.quantity = data.pendingCount;
          } else if (index == 2 && data.processingCount != 0) {
            item.quantity = data.processingCount;
          } else if (index == 3 && data.closedCount != 0) {
            item.quantity = data.closedCount;
          } else if (index == 4 && data.completedCount != 0) {
            item.quantity = data.completedCount;
          } else {
            item.quantity = 0;
          }
          return item;
        });
        console.log(this.tabPane, '统计数量')
      })
    },
  },
};
</script>

<style scoped lang="scss">
.afterSaleManagement {
  overflow: auto;
  .afterSale-title {
    font-size: 20px;
    padding: 10px 0 20px 20px;
  }
}
.el-tabs {
  margin: 0 20px 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #e4e7ed;
}
::v-deep .el-badge__content.is-fixed.is-dot {
  top:3px;
  right: 3px;
}

::v-deep .el-badge__content.is-fixed {
  top: 10px;
  right: 10px;
}

::v-deep .el-tabs__item {
  padding: 0 30px !important;
}

::v-deep
.el-tabs--top.el-tabs--card
> .el-tabs__header
.el-tabs__item:last-child {
  padding: 0 30px;
}

</style>
