<template>
  <div class="allForm">
    <el-form ref="formRef" label-width="100px" :model="formData">
      <el-form-item
        v-for="item in searchData"
        :key="item.prop"
        :label-width="item.labelWidth?item.labelWidth:'100px'"
        :label="item.label ? item.label + ':' : ''"
      >
        <el-input
          v-if="item.type == 'text'"
          v-model="formData[item.prop]"
          :placeholder="'请输入' + item.label"
          size="medium"
          clearable
        />
        <el-select
          v-if="item.type == 'select'"
          v-model="formData[item.prop]"
          clearable
          filterable
          :placeholder="'请选择' + item.label"
          size="medium"
        >
          <el-option
            v-for="item2 in item.selectArr"
            :key="item2.id"
            :label="item2.factoryName"
            :value="item2.factoryId"
          />
        </el-select>
        <el-date-picker
          v-if="item.type == 'DateTime'"
          v-model="formData[item.prop]"
          end-placeholder="结束日期"
          size="medium"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />

        <el-button
          v-for="item3 in item.typeList"
          :key="item3.code"
          size="medium"
          :loading="item3.loading"
          :type="item3.type ? item3.type : ''"
          @click="queryInfo(item3.code)"
        >
          {{ item3.name }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    props: {
      searchData: {
        type: Array,
        default: () => [],
      },
      loading:{
        type:Boolean
      }
    },
    data() {
      return {
        formData: {},
        loadingClick:false
      }
    },
    watch:{

    },
    methods: {
      queryInfo(code) {
        if (code == 'reset') {
          this.formData = {}
          this.$emit("searchChange", { code: code });
        } else {
          console.log(this.formData,code,'传啥');
          this.$emit('searchChange', { formData: this.formData, code: code })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }
  ::v-deep .el-form-item__label {
    font-size: 12px;
  }

  ::v-deep .el-icon-search {
    height: 0;
  }
</style>
